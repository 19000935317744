<template>
  <div>
    <div class="modal-header">
      Export Ticket List
      <h5><i class="fas fa-close cursor-pointer mx-2 pt-1 float-right" v-on:click="closeModal"></i></h5>
    </div>
    <div class="modal-body">
      <h6 class="pt-2 text-dark text-header">
        Ticket date range &nbsp;
      </h6>
      <div class="option-list w-100">
        <v-date-picker
          v-model="filters.dateRange"
          :placeholder="`${formatDate(filters.dateRange[0])} - ${formatDate(filters.dateRange[1])}`"
          :range="true"
          input-class="vue-datepicker w-100 text-muted border-0 px-2 my-1"
          class="w-100 form-control p-1"
        >
        </v-date-picker>
      </div>
      <br>
      <h6 class="text-header text-muted">Segments</h6>
      <MultiSelect
        :options="options.segments"
        :selected-options="filters.segment"
        placeholder="All segments"
        :isDisabled="this.user.role === 'Customer'"
        ref="multiselect-segment"
        @select="(items, last) => onSelect(items, last, 'segment')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-header text-muted">Clients</h6>
      <MultiSelect
        :options="options.clients.filter(r => filters.segment.length === 0 ? true : filters.segment.map(q => q.value).includes(r.segment))"
        :selected-options="filters.client"
        placeholder="All clients"
        :isDisabled="this.user.role === 'Customer'"
        ref="multiselect-client"
        @select="(items, last) => onSelect(items, last, 'client')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-header text-muted">Business Unit</h6>
      <MultiSelect
        :options="options.businessUnits"
        :selected-options="filters.businessUnit"
        placeholder="All labels"
        ref="multiselect-businessUnit"
        @select="(items, last) => onSelect(items, last, 'businessUnit')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-header text-muted">Agents</h6>
      <MultiSelect
        :options="options.agents.filter(r => filters.businessUnit.length === 0 ? true : filters.businessUnit.map(q => q.value).some(q => r.bunit.includes(q)))"
        :selected-options="filters.agent"
        placeholder="All agents"
        ref="multiselect-agent"
        @select="(items, last) => onSelect(items, last, 'agent')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-header text-muted">Labels</h6>
      <MultiSelect
        :options="options.labels.filter(r => filters.segment.length === 0 ? true : filters.segment.map(q => q.value).includes(r.segment))"
        :selected-options="filters.label"
        placeholder="All labels"
        ref="multiselect-label"
        @select="(items, last) => onSelect(items, last, 'label')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-dark text-header">Ticket classification</h6>
      <MultiSelect
        :options="options.issue_classifications"
        :selected-options="filters.issueClassification"
        placeholder="All classifications"
        ref="multiselect-issueClassification"
        @select="(items, last) => onSelect(items, last, 'issueClassification')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-dark text-header">Ticket state</h6>
      <MultiSelect
        :options="options.states"
        :selected-options="filters.isActive"
        placeholder="All states"
        ref="multiselect-isActive"
        @select="(items, last) => onSelect(items, last, 'isActive')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-dark text-header">Ticket status</h6>
      <MultiSelect
        :options="options.statuses"
        :selected-options="filters.status"
        placeholder="All statuses"
        ref="multiselect-status"
        @select="(items, last) => onSelect(items, last, 'status')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-dark text-header">Ticket severity</h6>
      <MultiSelect
        :options="options.priorities"
        :selected-options="filters.priority"
        placeholder="All severities"
        ref="multiselect-priority"
        @select="(items, last) => onSelect(items, last, 'priority')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-dark text-header">Ticket agent severity</h6>
      <MultiSelect
        :options="options.priorities"
        :selected-options="filters.agentPriority"
        placeholder="All agent severities"
        ref="multiselect-agentPriority"
        @select="(items, last) => onSelect(items, last, 'agentPriority')"
      >
      </MultiSelect>
      <br>
      <h6 class="text-dark text-header">File format</h6>
      <ModelSelect
        v-model="filters.file_format"
        placeholder="Select file format"
        :options="options.file_formats"
      >
      </ModelSelect>
      <br><br>
      <div class="btn btn-block btn-primary bg-primary-default w-100" v-on:click="setFilters">
        Generate report
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { MultiSelect, ModelSelect } from 'vue-search-select'

  export default {
    components: {
      MultiSelect, ModelSelect
    },
    data() {
      return {
        user: this.$userData.user,
        clients: [],
        date: '',
        filters: {
          dateRange: this.$defaultDates,
          bunit: [],
          client: [],
          segment: [],
          agent: [],
          label: [],
          businessUnit: [],
          isActive: [],
          issueClassification: [],
          status: [],
          priority: [],
          agentPriority: [],
          file_format: 'csv'
        },
        options: {
          clients: [],
          business_units: [],
          segments: this.$segments,
          agents: [],
          labels: [],
          businessUnits: [],
          states: this.$state,
          statuses: this.$statuses,
          issue_classifications: this.$issueClassifications,
          priorities: this.$priorities,
          file_formats: [
            { key: 'csv', text: 'CSV', value: 'csv' },
            { key: 'pdf', text: 'PDF', value: 'pdf' },
            { key: 'xslx', text: 'Excel', value: 'xslx' }
          ]
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      closeModal: function(){
        this.$modal.toggle('ticket-list');
      },
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("MMM DD, YYYY")
      },
      onSelect (items, last, filter_key, skip_focus = false) {
        this.filters[filter_key] = items
        if(!skip_focus)
          this.$refs[`multiselect-${filter_key}`].$el.querySelectorAll('input')[0].click()
      },
      async getClients(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.clients = response.data.clients.map((r) => {
            return { key: r._id, text: r.name, value: r._id, segment: r.segment }
          })
          if (this.user.role === 'Customer')
            this.onSelect([{ key: this.user.client._id, text: this.user.client.name, value: this.user.client._id }], this.user.client._id, 'client', true)
          this.getTags()
        } catch (error) {
          console.log(error)
        }
      },
      async getUsers(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Manager,Agent`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.agents = response.data.users.filter(r => r.roles === "Agent" || (r.roles === "Manager" && r.isAgent)).map((r) => {
            return { key: r._id, text: r.name, value: r._id, email: r.email, bunit: r.bunit.map(q => q._id) }
          })
        } catch (error) {
          console.log(error)
        }
      },
      async getTags(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/tags?type=label`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.labels = response.data.tags.map(r => {
            return { key: r._id, text: r.name, value: r.code }
          })
        } catch (error) {
          console.log(error)
        }
      },
      async getBusinessUnits(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/bunits`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.businessUnits = response.data.bunits.filter(r => r.isActive).filter(r => {
            return this.user.role == "Admin" || this.user.bunit.map(q => q._id).includes(r._id)
          }).map(r => {
            return { key: r._id, text: r.name, value: r._id }
          })
        } catch (error) {
          console.log(error)
        }
      },
      setFilters(){
        let filters = this.filters
        let params = {
          startsAt: moment(filters.dateRange[0]).startOf('day'),
          endsAt: moment(filters.dateRange[1]).endOf('day'),
          bunit: filters.bunit,
          client: filters.client.map(r => r.value),
          isActive: filters.isActive.map(r => r.value),
          issueClassification: filters.issueClassification.map(r => r.value),
          status: filters.status.map(r => r.value),
          priority: filters.priority.map(r => r.value),
          agentPriority: filters.agentPriority.map(r => r.value),
          file_format: filters.file_format
        }
        this.generateReport(params)
      },
      async generateReport(params){
        if(!params || Object.keys(params).length === 0)
          return 0
        this.loader(true);
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/reports/ticketList`,
            params,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          if(response.data)
            this.$downloadFile(params.file_format, response.data, 'ITS_tickets_' + new Date().valueOf(), "a3")
          this.loader(false)
        } catch (error) {
          console.log(error)
          this.loader(false)
        }
      }
    },
    mounted: function() {
      this.getBusinessUnits()
      this.getUsers()
      // this.getTags()
      this.getClients()
    }
  }
</script>
